import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

import Cartao from "../../components/Cartao";

import { UserCard, UserCardHandler } from "../../interfaces/userCard";
import { PlanDetailDto, PlanDetailDtoHandler } from "../../dtos/PlanDetail";
import { PaymentMethodType, PeriodType } from "../../enums";
import { PlanMainDto, PlanMainDtoHandler } from "../../dtos";
import api from "../../services/api";
import "./paymentStyle.scss";
import "./newPaymentStyle.scss";
import { BACKGROUND_LIME_GREEN, BACKGROUND_WHITE, BORDER_GRAY_100, TEXT_DARK_BLUE, TEXT_GRAY_600, TEXT_GRAY_700, TEXT_WHITE } from "../../constants/colors";
import { formatPrice } from "../../utils";
import FormSteps from "../../components/Form/FormSteps";
import { decryptDiscount } from "../../utils/decryptDiscount";
import {
  InitiateCheckout,
  Purchase,
} from "../../utils/facebookPixel/facebookPixelEvent";

const steps: any = [
  { number: 1, title: "Seu Plano", done: true, current: false },
  { number: 2, title: "Dados Pessoais", done: true, current: false },
  { number: 3, title: "Pagamento", done: false, current: true },
];

export default function Payment() {
  const navigate = useNavigate();
  const { id, mercado, clientId, ad, userToken } = useParams();
  const [URLSearchParams] = useSearchParams()
  const encryptedDiscount = URLSearchParams.get("d");
  const [decryptedDiscount, setDecryptedDiscount] = useState<number>(0);

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>(
    PaymentMethodType.creditCard
  );
  const paymentMethodRef = useRef(paymentMethod);
  const [selectPeriod, setSelectPeriod] = useState<PeriodType>(
    PeriodType.Monthly
  );
  const selectedPeriodRef = useRef(selectPeriod);
  const [userCard, setUserCard] = useState<UserCard>(
    UserCardHandler.getDefault()
  );
  const [planDetail, setPlanDetail] = useState<PlanDetailDto>(
    PlanDetailDtoHandler.getDefault()
  );
  const [planMain, setPlanMain] = useState<PlanMainDto>(
    PlanMainDtoHandler.getDefault()
  );
  const [contId, setContId] = useState();
  const contIdRef = useRef(contId);
  const [titleId, setTitleId] = useState();
  const titleIdRef = useRef(titleId);
  const [isPortion, setIsPortion] = useState(planDetail.installment);
  const isPortionRef = useRef(isPortion);
  const [loading, setLoading] = useState(false);
  const [marketSelect, setMarketSelect] = useState(planDetail.planMarket);
  const isMarketSelect = useRef(marketSelect);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [statusPaymento, setStatusPayment] = useState("");
  const [textButton, setTextButton] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadPlanMarket();
    handleChangePaymentMethod(paymentMethod);
    setLoading(true);
  }, []);

  async function loadPlanMarket() {
    await api
      .get(`/planos/id/${id}`)
      .then((res) => {
        const newPlan = PlanMainDtoHandler.getByDataBase(res.data[0]);
        setPlanMain(newPlan);
        const marketPlan = newPlan.planMarket;
        isMarketSelect.current = marketPlan;
        setMarketSelect(isMarketSelect.current);

        const newPlanDetails = PlanDetailDtoHandler.getByDatabase(
          res.data[0]
        );
        const portionPlan = newPlanDetails.installment;
        setPlanDetail(newPlanDetails);
        isPortionRef.current = String(portionPlan);
        setIsPortion(isPortionRef.current);
        userCard.portion = isPortionRef.current;
        setLoading(false);
      })
      .catch(() => console.log("Erro ao buscar o mercado do plano"));
  }

  async function loadContract() {
    const cont_plano_id = planDetail.id;
    const cont_cliente_id = clientId;
    const cont_forma_pgto = planDetail.paymentMethod;
    const cont_mercado = String(marketSelect);
    const cont_publicidade = Number(ad) ?? 0;
    const descontocomercial = decryptedDiscount;

    const body = {
      cont_plano_id,
      cont_cliente_id,
      cont_forma_pgto,
      cont_mercado,
      cont_publicidade,
      descontocomercial
    };

    await api
      .post("/contratos/adiciona", body)
      .then((response) => {
        const newContId = response.data.cont_id;
        contIdRef.current = newContId;
        const newTitle = response.data.titulo_id;
        titleIdRef.current = newTitle;
        setContId(newContId);
        setTitleId(newTitle);
      })
      .catch(() => console.log("Erro ao criar o contrato"));
  }

  function handleChangePaymentMethod(method: PaymentMethodType): void {
    paymentMethodRef.current = method;
    setPaymentMethod(method);
    setIsPortion(isPortionRef.current);

    if (method === PaymentMethodType.boleto) {
      setIsPortion(isPortionRef.current);
    }
    if (method === PaymentMethodType.pix) {
      setIsPortion(isPortionRef.current);
    }
    if (selectedPeriodRef.current === PeriodType.SemiAnnual) {
      setIsPortion(isPortionRef.current);
      if (method === PaymentMethodType.boleto) {
        setIsPortion(isPortionRef.current);
      }
      if (method === PaymentMethodType.pix) {
        setIsPortion(isPortionRef.current);
      }
    }

    loadPlanMarket();
  }
  //manipulandoe e enviando dados do cartao
  async function handleSaveCard(userData: UserCard) {
    await loadContract();
    setButtonStatus(true);
    userCard.cardValidity = userCard.cardMonth + "/20" + userCard.cardYear;
    userCard.id = String(contIdRef.current || "");
    userCard.title = String(titleIdRef.current || "");
    const strgNumberCard = userCard.cardNumber.replace(/[^0-9]/g, "");
    userCard.cardNumber = strgNumberCard;
    const data = UserCardHandler.toDatabase(userData);
    console.log(data)

    await api
      .post("/cobrancas/recorrencia/cartao", data[0])
      .then((res) => {
        console.log(res.data)
        handlePaymentStatus(res.data.paymentStatusId, res.data.paymentStatus);
      })
      .catch((error) => {
        console.log(error)
        setButtonStatus(false);
        setStatusPayment(`Número do cartão de crédito inválido`);
        setTextButton(true);
      })
      .finally(() => setButtonStatus(false));
  }

  function handlePortion(data: any) {
    setIsPortion(data);
  }

  function handlePaymentStatus(statusId: any, status: any) {
    if (statusId === 2 || statusId === 1) {
       Purchase();
      return navigate(`/pagamento/afterPayment`);
    }

    setButtonStatus(false);
    setStatusPayment(status);
    setTextButton(true);

    return;
  }

  function handleChangeMarket(market: any) {
    setMarketSelect(market);
  }

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(userToken));
  }, [userToken])

  useEffect(() => {
    InitiateCheckout();
    setDecryptedDiscount(decryptDiscount(encryptedDiscount));
  }, []);

  return (
      <Box display={"flex"} justifyContent={"center"} marginBottom={"50px"}>
        <Box
          bgcolor={BACKGROUND_WHITE}
          border={"1px solid"}
          borderColor={BORDER_GRAY_100}
          borderRadius={"10px"}
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          justifyContent={"center"}
          maxWidth={"460px"}
          width={"100%"}
        >
          <FormSteps steps={steps} />
          <Box
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          justifyContent={"center"}
          mt={2}
          mx={2}
          borderRadius={"8px"}
          boxShadow={"0px 0px 15px 0px #0000001c"}
          >
              <Box
              bgcolor={BACKGROUND_LIME_GREEN}
              borderRadius={"10px 10px 0 0"}
              padding={2}
              sx={{
                backgroundImage:
                  Number(planDetail.id) >= 2
                    ? "linear-gradient(280.24deg, #69c462 -22.13%, #00113c 96.65%)"
                    : "",
              }}
              width={"100%"}
            >
              <Typography
                className={`${Number(planDetail.id) >= 2 ? "plan_title_detach" : ""}`}
                color={TEXT_WHITE}
                fontSize={24}
                fontWeight={500}
                textAlign={"center"}
                variant={"h6"}
              >
                Plano {planDetail.name}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="subtitle2"
                textAlign={"center"}
                fontWeight={"normal"}
                color={TEXT_GRAY_700}
              >
                Mensalmente
              </Typography>
              <Box display={"flex"} justifyContent={"center"} alignItems={"end"}>
                <Typography
                  variant="subtitle1"
                  sx={{ mr: 1, mb: 0.5 }}
                  fontWeight={"normal"}
                >
                  R$
                </Typography>
                <Typography
                  variant="h4"
                  color={TEXT_GRAY_700}
                  fontSize={"2.65rem"}
                  fontWeight={800}
                >
                  {formatPrice((planDetail.value - decryptedDiscount * 100) / parseInt(planDetail.installment))}
                </Typography>
              </Box>
            </Box>
            {decryptedDiscount > 0 && (
                <Typography
                    variant="subtitle1"
                    sx={{ mr: 1, mb: 0.5 }}
                    fontWeight={"normal"}
                  >
                    Desconto de R${decryptedDiscount} aplicado!
                </Typography>
              )
            }
            <Button
              variant="text"
              sx={{
                color: TEXT_DARK_BLUE,
                textTransform: "lowercase",
                textDecoration: "underline",
                marginBottom: 1,
              }}
              onClick={() => {navigate(-1)}}
            >
              Trocar
            </Button>
          </Box>
          <div
            className={!planDetail.planMarket ? "marketContainer" : "marketContainerOff"}
          >
            <div className="contentMarket">
              <h2 className="marketTittle">Seleciona seu mercado:</h2>
              <FormControl size="small" color="success">
                <InputLabel>Mercado</InputLabel>
                <Select
                  id="demo-simple-select"
                  className="selectMarket"
                  label="Mercado"
                  onChange={(e) => {
                    handleChangeMarket(e.target.value);
                  }}
                >
                  <MenuItem value={1}>Soja</MenuItem>
                  <MenuItem value={2}>Milho</MenuItem>
                  <MenuItem value={3}>Gado e Proteina Animal</MenuItem>
                  <MenuItem value={5}>Leite</MenuItem>
                  <MenuItem value={7}>Frango e Suínos</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <Cartao
            user={userCard}
            onChange={(u) => setUserCard(u)}
            onSave={(e) => handleSaveCard(e)}
            period={selectPeriod}
            value={planDetail.value}
            planId={id || ""}
            onPortion={(e) => handlePortion(e)}
            portion={planDetail.installment || ""}
            loading={loading}
            marketPlan={marketSelect}
            button={buttonStatus}
            textButton={textButton}
            textValidateErro={statusPaymento}
          />
        </Box>
      </Box>
  );
}
