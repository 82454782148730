import { useState, useEffect } from "react";
import axios from "axios";
import api from "../../services/api";
import { useParams } from "react-router-dom";
import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { CountdownTimer } from "../../components/Home";
import Tab from "../../components/Plans/Tab";

interface Plan {
    plan_id: number;
    plan_nome: string;
    plan_descricao: string;
    plan_valor: number;
    plan_qtd_dias: number;
    plan_max_parcelas: number;
    plan_mercado: number | null;
    plan_interval: string;
}

export default function SubscriptionCustomization({ variant = "normal" }) {
    const [step, setStep] = useState(1);
    const [preferences, setPreferences] = useState<{ industry?: string; frequency?: string }>({});
    const [plans, setPlans] = useState<Plan[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
    const [selectedMarkets, setSelectedMarkets] = useState<string[]>([]);

    // Verificando origem de publicidade
    const { ad } = useParams();
    // reseta LocalStorage e verifica se o ad não é undefined
    //localStorage.removeItem("ad");
    if (ad !== undefined) {
        const publi = ad;
        localStorage.setItem("ad", String(publi));
    }

    const plansVariantName = process.env.REACT_APP_PLANS_VARIANT ?? "normal"
    const plansCustomEnv = process.env.REACT_APP_PLANS_CUSTOM ?? "";
    const plansDefaultEnv = process.env.REACT_APP_PLANS_DEFAULT ?? "";
    const plansCountdownEnv = process.env.REACT_APP_PLANS_COUNTDOWN ?? "";

    const plansDefault = plansDefaultEnv ? JSON.parse(plansDefaultEnv) : { active: false };
    const plansCustom = plansCustomEnv ? JSON.parse(plansCustomEnv) : { active: false };
    const plansCountdown = plansCountdownEnv ? JSON.parse(plansCountdownEnv) : "";

    const getAllSubscriptions = async () => {
        let subscription
        if (plansVariantName !== "normal") {
            subscription = await axios.all([
                api.get(`/planos/id/${plansCustom[0].plan_intermediate_id}`).then((response) => (response.data[0])),
                api.get(`/planos/id/${plansCustom[1].plan_advanced_id}`).then((response) => (response.data[0])),
                api.get(`/planos/id/${plansCustom[2].plan_basic_id}`).then((response) => (response.data[0]))
            ])
        } else {
            subscription = await axios.all([
                api.get(`/planos/id/${plansDefault[0].plan_intermediate_id}`).then((response) => (response.data[0])),
                api.get(`/planos/id/${plansDefault[1].plan_advanced_id}`).then((response) => (response.data[0])),
                api.get(`/planos/id/${plansDefault[2].plan_basic_id}`).then((response) => (response.data[0]))
            ])
        }
        console.log(subscription)
        setPlans(subscription)
    }

    const prevStep = () => {
        setStep((prev) => prev - 1);
    };

    const nextStep = () => {
        setStep((prev) => prev + 1);
    };

    const handlePreferenceSelection = (key: string, value: string) => {
        setPreferences((prev) => ({ ...prev, [key]: value }));
        nextStep()
    };

    const handleCheckboxChange = (market: string) => {
        setSelectedMarkets((prev) =>
            prev.includes(market) ? prev.filter((m) => m !== market) : [...prev, market]
        );
    };

    useEffect(() => {
        const industry = preferences.industry
        // const frequency = preferences.frequency
        const selectedLength = selectedMarkets.length === 1 ? null : true
        if (industry) {
            const matchedPlan = plans.find((plan) => selectedLength === null ? plan.plan_mercado === selectedLength : true);
            setSelectedPlan(matchedPlan || null);
        }
    }, [preferences, plans, selectedMarkets]);

    useEffect(() => {
        getAllSubscriptions()
    }, []);

    return (
        <div>
            {plansCountdown.active && (
                <div className="flex justify-center sticky top-0 bg-[#fce303] z-[2147483647] mb-16">
                    <CountdownTimer title={plansCountdown.title} limitDate={plansCountdown.limit_date} titleFont="text-[#ff0000] text-lg" numberFont="text-[#ff0000] text-3xl" labelFont="text-[#ff0000] text-xs" />
                </div>
            )}
            <div className="p-6 max-w-4xl flex flex-col items-center gap-8 justify-center mx-auto">
                <Typography variant="h5">Ache o seu plano ideal com base nas suas preferências</Typography>
                {step === 1 && (
                    <div>
                        <h2 className="text-xl font-bold mb-4">Qual área você prefere?</h2>
                        <div className="grid gap-4">
                            <Button onClick={() => handlePreferenceSelection("industry", "Agriculture")}>Agricultura</Button>
                            <Button onClick={() => handlePreferenceSelection("industry", "Wholesale")}>Pecuária</Button>
                            <Button onClick={() => handlePreferenceSelection("industry", "All")}>Ambos</Button>
                        </div>
                    </div>
                )}
                {/* {step === 2 && (
                    <div>
                        <h2 className="text-xl font-bold mb-4">Qual período você prefere?</h2>
                        <div className="grid gap-4">
                            <Button onClick={() => handlePreferenceSelection("frequency", "Monthly")}>Mensal</Button>
                            <Button onClick={() => handlePreferenceSelection("frequency", "Semiannual")}>Semestral</Button>
                            <Button onClick={() => handlePreferenceSelection("frequency", "Annual")}>Anual</Button>
                        </div>
                    </div>
                )} */}
                {step === 2 && (
                    <div>
                        <h2 className="text-xl font-bold mb-4">Quais mercados você quer acompanhar?</h2>
                        <div className="grid gap-4">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedMarkets.includes("soy")}
                                        onChange={() => handleCheckboxChange("soy")}
                                    />
                                }
                                label="Soja e Trigo"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={selectedMarkets.includes("corn")}
                                        onChange={() => handleCheckboxChange("corn")}
                                    />
                                }
                                label="Milho"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedMarkets.includes("ox")}
                                        onChange={() => handleCheckboxChange("ox")}
                                    />
                                }
                                label="Gado e Proteína Animal"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedMarkets.includes("milk")}
                                        onChange={() => handleCheckboxChange("milk")}
                                    />
                                }
                                label="Leite"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedMarkets.includes("chicken")}
                                        onChange={() => handleCheckboxChange("chicken")}
                                    />
                                }
                                label="Frango e Suínos"
                            />
                        </div>
                        <div className="flex gap-4 mt-4">
                            <Button onClick={prevStep}>Voltar</Button>
                            <Button onClick={nextStep}>Continuar</Button>
                        </div>
                    </div>
                )}
                {step === 3 && selectedPlan && (
                    <div className="flex flex-col gap-2 animate-fadeinup">
                        <h2 className="text-xl text-center font-bold mb-4">Plano recomendado para você!</h2>
                        <div>
                            <Tab
                                isIntermediatePlan
                                variant={variant}
                                plan={selectedPlan.plan_id}
                                price={
                                    selectedPlan.plan_valor / selectedPlan.plan_max_parcelas
                                }
                                priceInstallments={selectedPlan.plan_max_parcelas}
                                commoditys={
                                    "Análises em vídeo diárias de Soja, Milho, Trigo*, Boi, Leite, Frango e Suíno + Séries exclusivas"
                                }
                                perks={[
                                    "Análises de mercado diárias de TODOS os mercados disponíveis",
                                    "Gráficos (1 ano)",
                                    "Cotações",
                                    "Acesso via app, computador, smartphone ou tablet",
                                ]}
                                title={`PLANO ${selectedPlan.plan_nome}`}
                                market={selectedPlan.plan_mercado}
                            />
                        </div>
                        <div className="flex gap-4 mt-4">
                            <Button onClick={() => setStep(1)} className="w-full">recomeçar</Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
