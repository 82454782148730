import api from "../../services/api";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { Loading } from "../../assets/imgs";
import {
  loadAuth,
  loadSituation,
} from "../../utils/apiFunctions/auth";
import Cookies from "js-cookie";

export default function Quotes() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    loadAuth(handleAuth);
    loadSituation(handleSituation);
    loadingAnimation();
  }, []);

  //manipulando o resultado do get da Auth
  function handleAuth(auth: boolean) {
    if (!auth) return;

    localStorage.removeItem("user");
    navigate("/login");
  }

  function handleSituation(data?: any) {
    if (data.situation !== "Pago") {
      navigate("/dashboard/myAccount");
    }
  }

  async function loadingAnimation() {
    await api
      .get(
        `https://web.ruralbusiness.com.br/embed/cotacoes?token=${Cookies.get("x-access-token")}&style=${decodeURIComponent(JSON.stringify({ height: 820 }))}`
      )
      .then((res) => {
        if (res) {
          setLoading(false);
        }
      });
  }

  useEffect(() => {
    const handleMessage = (event: MessageEvent<any>) => {
      // Certifique-se de verificar a origem do evento para segurança
      if (event.origin !== "https://ruralbusiness.com.br") return;

      const iframeHeight = event.data;
      if (iframeRef.current) {
        console.log(iframeRef.current.style.height)
        iframeRef.current.style.height = `${iframeHeight}px`;
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const LoadQuotes = () => {
    return (
      <>
        {loading ? (
          <Loading />
        ) : (
          <iframe
            src={`https://web.ruralbusiness.com.br/embed/cotacoes?token=${Cookies.get("x-access-token")}&style=${decodeURIComponent(JSON.stringify({ height: 820 }))}`}
            width="100%"
            height="820px"
            scrolling="no"
            style={{ maxWidth: "1024px", overflowY: "hidden" }}
          ></iframe>
        )}
      </>
    );
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          borderRadius: "15px",
        }}
        height={'100%'}
        maxWidth={1056}
        mx={'auto'}
        mb={6}
      >
        <Grid item width={"100%"} xs={12} sx={{ overflow: "hidden" }}>
          <LoadQuotes />
        </Grid>
      </Grid>
    </>
  );
}
