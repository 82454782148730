export { default as Home } from "./Home/Home";
export { default as Plans } from "./Plans/Plans";
export { default as SubscriptionCustomization } from "./SubscriptionCustomization/SubscriptionCustomization";
export { default as HelpBadge } from "./Plans/HelpBadge";
export { default as Form } from "./Form/Form";
export { default as NotFound } from "./NotFound/NotFound";
export { default as Payment } from "./Payment/Payment";
export { default as Forgot } from "./Forgot/Forgot";
export { default as Login } from "./Login/Login";
export { default as RenewPayment } from "./RenewPayment/RenewPayment";
export { default as RenewPaymentMp } from "./MercadoPago/RenewPayment/RenewPaymentMp";
export { default as CookiesPolicy } from "./CookiesPolicy/CookiesPolicy";
export { default as UseLicenseAgreement } from "./UseLicenseAgreement/UseLicenseAgreement";
export { default as RuralBusinessCommitment } from "./RuralBusinessCommitment/RuralBusinessCommitment";
export { default as PrivacyPolicy } from "./PrivacyPolicy/PrivacyPolicy";
export { default as OneNews } from "./OneNews/OneNews";
