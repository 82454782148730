import api from "../../services/api";

export type PostLoginClient = {
  error: boolean;
  token: string;
};

export type PreRegistrationRequest = {
  pre_nome: string;
  pre_email: string;
  pre_telefone: string;
  pre_plano: number;
};

const postPreRegistration = async (values?: PreRegistrationRequest) => {
  const { data } = await api.post("/clientes/precadastro", {
    ...values,
  });

  if (data.error) {
    throw new Error(JSON.stringify(data));
  }

  return data as PostLoginClient;
};

export { postPreRegistration };
