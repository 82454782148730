import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import "@splidejs/react-splide/css";
import { PlanMainDto, PlanMainDtoHandler } from "../../dtos";
import api from "../../services/api";
import Tab from "../../components/Plans/Tab";
import { PagePlanView } from "../../utils/facebookPixel/facebookPixelEvent";
import scrollToTop from "../../utils/scrollToTop";
import "../styles/planStyle.scss";
import HelpBadge from "./HelpBadge";
import { CountdownTimer } from "../../components/Home";
import { getPlanById } from "../../utils/request";

export default function Plans({ variant = "normal" }) {
  // Verificando origem de publicidade
  const { ad } = useParams();
  // reseta LocalStorage e verifica se o ad não é undefined
  //localStorage.removeItem("ad");
  if (ad !== undefined) {
    const publi = ad;
    localStorage.setItem("ad", String(publi));
  }

  const plansVariantName = process.env.REACT_APP_PLANS_VARIANT ?? "normal"
  const plansCustomEnv = process.env.REACT_APP_PLANS_CUSTOM ?? "";
  const plansDefaultEnv = process.env.REACT_APP_PLANS_DEFAULT ?? "";
  const plansCountdownEnv = process.env.REACT_APP_PLANS_COUNTDOWN ?? "";

  const plansDefault = plansDefaultEnv ? JSON.parse(plansDefaultEnv) : { active: false };
  const plansCustom = plansCustomEnv ? JSON.parse(plansCustomEnv) : { active: false };
  const plansCountdown = plansCountdownEnv ? JSON.parse(plansCountdownEnv) : "";

  // console.log(plansCountdown)

  const [planBasic, setPlanBasic] = useState<PlanMainDto>(
    PlanMainDtoHandler.getDefault()
  );
  const [planIntermediate, setPlanIntermediate] = useState<PlanMainDto>(
    PlanMainDtoHandler.getDefault()
  );
  const [planAdvanced, setPlanAdvanced] = useState<PlanMainDto>(
    PlanMainDtoHandler.getDefault()
  );

  const [promotionPlans, setPromotionPlans] = useState<any>({
    planBasic: PlanMainDtoHandler.getDefault(),
    planIntermediate: PlanMainDtoHandler.getDefault(),
    planAdvanced: PlanMainDtoHandler.getDefault(),
  });

  async function loadPlanMain() {
    /*api
      .get("/planos/all")
      .then((response) => {
        setPlanIntermediate(PlanMainDtoHandler.getByDataBase(response.data[0]));
        setPlanAdvanced(PlanMainDtoHandler.getByDataBase(response.data[1]));
        setPlanBasic(PlanMainDtoHandler.getByDataBase(response.data[2]));
        // setPlanExpert(PlanMainDtoHandler.getByDataBase(response.data[3]));
      })
      .catch(() => console.log("Erro ao carregar planos do usuário."));
      */
    if (plansVariantName !== "normal") {
      await Promise.all([getPlanById(plansCustom[0].plan_intermediate_id), getPlanById(plansCustom[1].plan_advanced_id), getPlanById(plansCustom[2].plan_basic_id)])
        .then((res) => {
          // console.log(res)
          setPromotionPlans((prevState: any) => ({
            ...prevState,
            planBasic: PlanMainDtoHandler.getByDataBase(res[2][0]),
            planIntermediate: PlanMainDtoHandler.getByDataBase(res[0][0]),
            planAdvanced: PlanMainDtoHandler.getByDataBase(res[1][0]),
          }));
        })
    } else {
      await Promise.all([getPlanById(plansDefault[0].plan_intermediate_id), getPlanById(plansDefault[1].plan_advanced_id), getPlanById(plansDefault[2].plan_basic_id)])
        .then((res) => {
          console.log(res)
          setPlanIntermediate(PlanMainDtoHandler.getByDataBase(res[0][0]));
          setPlanAdvanced(PlanMainDtoHandler.getByDataBase(res[1][0]));
          setPlanBasic(PlanMainDtoHandler.getByDataBase(res[2][0]));
        })
    }
  }

  useEffect(() => {
    scrollToTop();
    loadPlanMain();
    PagePlanView(); // Pixel facebook
  }, []);

  console.log(planAdvanced)

  return (
    <div>
      {plansCountdown.active && (
        <div className="flex justify-center sticky top-0 bg-[#fce303] z-[2147483647] mb-16">
          <CountdownTimer title={plansCountdown.title} limitDate={plansCountdown.limit_date} titleFont="text-[#ff0000] text-lg" numberFont="text-[#ff0000] text-3xl" labelFont="text-[#ff0000] text-xs" />
        </div>
      )}
      <div
        className={`grid grid-cols-1 md:grid-cols-3 justify-center gap-y-10 justify-items-center w-fit mx-auto px-1 py-10`}
      >
        <Tab
          isIntermediatePlan
          variant={variant}
          plan={variant === "normal"
            ? planIntermediate.id : promotionPlans.planIntermediate.id}
          price={
            variant === "normal"
              ? planIntermediate.value / planIntermediate.installment
              : promotionPlans.planIntermediate.value /
              promotionPlans.planIntermediate.installment
          }
          priceInstallments={variant === "normal"
            ? planIntermediate.installment : promotionPlans.planIntermediate.installment}
          oldPrice={planIntermediate.value / planIntermediate.installment}
          oldPriceInstallments={planIntermediate.installment}
          commoditys={
            "Análises em vídeo diárias de Soja, Milho, Trigo*, Boi, Leite, Frango e Suíno + Séries exclusivas"
          }
          perks={[
            "Análises de mercado diárias de TODOS os mercados disponíveis",
            "Gráficos (1 ano)",
            "Cotações",
            "Acesso via app, computador, smartphone ou tablet",
          ]}
          title={`PLANO ${variant === "normal"
            ? planIntermediate.title : promotionPlans.planIntermediate.title}`}
          market={planIntermediate.planMarket}
        />
        <Tab
          isAdvancedPlan
          variant={variant}
          plan={variant === "normal"
            ? planAdvanced.id : promotionPlans.planAdvanced.id}
          price={
            variant === "normal"
              ? planAdvanced.value / planAdvanced.installment
              : promotionPlans.planAdvanced.value /
              promotionPlans.planAdvanced.installment
          }
          priceInstallments={variant === "normal"
            ? planAdvanced.installment : promotionPlans.planAdvanced.installment}
          oldPrice={planAdvanced.value / planAdvanced.installment}
          oldPriceInstallments={planAdvanced.installment}
          commoditys={
            "Análises em vídeo diárias de Soja, Milho, Trigo*, Boi, Leite, Frango e Suíno + Séries exclusivas"
          }
          specialPerks={[
            {
              title: "Alertas",
              text: "de mercado em texto",
            },
            { title: "CME e B3", text: "Fechamento das bolsas" },
            { title: "Gráficos", text: "disponíveis 3 anos" },
          ]}
          perks={[
            "Análises de mercado diárias de TODOS os mercados disponíveis",
            "Cotações",
            "Acesso via app, computador, smartphone ou tablet",
          ]}
          title={`PLANO ${variant === "normal"
            ? planAdvanced.title : promotionPlans.planAdvanced.title}`}
          market={planAdvanced.planMarket}
        />
        <Tab
          variant={variant}
          plan={variant === "normal"
            ? planBasic.id : promotionPlans.planBasic.id}
          price={
            variant === "normal"
              ? planBasic.value / planBasic.installment
              : promotionPlans.planBasic.value /
              promotionPlans.planBasic.installment
          }
          priceInstallments={variant === "normal"
            ? planBasic.installment : promotionPlans.planBasic.installment}
          oldPrice={planBasic.value / planBasic.installment}
          oldPriceInstallments={planBasic.installment}
          commoditys={
            "Análises em vídeo diárias de SOMENTE 1 MERCADO a sua escolha: Soja, Milho, Boi, Leite, Frango, Suíno + Séries exclusivas"
          }
          perks={[
            "Análises de mercado diárias em vídeo de 1 mercado",
            "Gráficos de apenas 1 mercado (1 ano)",
            "Cotações de apenas 1 mercado",
            "Acesso via app, computador, smartphone ou tablet",
          ]}
          title={`PLANO ${variant === "normal"
            ? planBasic.title : promotionPlans.planBasic.title}`}
          market={planBasic.planMarket}
        />
      </div>
      <HelpBadge />
    </div>
  );
}
