import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { User, UserHandler } from "../../interfaces";
import { UserContDto, UserContDtoHandler } from "../../dtos";
import api from "../../services/api";

import {
  AddToCart,
  CompleteRegistration,
} from "../../utils/facebookPixel/facebookPixelEvent";
import UserForm from "./UserForm";
import { postPreRegistration } from "../../utils/apiFunctions/postPreRegistration";

AddToCart(); // Pixel facebook
const getTokenUrl = `${process.env.REACT_APP_API_RB}/clientes/login`;

async function loginUser(credentials: any) {
  return await fetch(getTokenUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export default function Signature() {
  const navigate = useNavigate();
  const { planId, planValue, mercado } = useParams();
  const [userForm, setUserForm] = useState<User>(UserHandler.getDefault());
  const [userCont, setUserCont] = useState<UserContDto>(
    UserContDtoHandler.getDefault()
  );
  const [buttonStatus, setButtonStatus] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("user");
    //Se existir token de usuário será redirecionado para a Dashboard
    if (token !== "" && token !== null && token !== undefined) {
      navigate("/dashboard/myAccount");
    }
  }, []);

  //criando user e retornando id
  async function createUser(user: any) {
    setButtonStatus(true);
    await api
      .post("/clientes/cadastro", user)
      .then((response) => {
        handleNavigate(response.data.cli_id);
        CompleteRegistration(); // Pixel facebook
      })
      .catch(() => {
        console.log("Erro ao criar usuário");
        setButtonStatus(false);
      })
      .finally(() => {
        setButtonStatus(false);
      });
  }
  //mainipulando os dados do user para navegar pro pagamento
  async function handleNavigate(userId: any) {
    userCont.clientId = userId;
    await handleLogin();

    // CIELO
    // navigate(`/pagamento/${planId}/${userCont.clientId}`);

    //MERCADO PAGO
    navigate(`/pagamento/mp/${planId}/${mercado}/${userCont.clientId}`);
  }

  async function handleLogin() {
    const data = UserHandler.toDatabase(userForm);
    const userData = data[0];
    const user = userData.cli_cpf_cnpj;
    const password = userData.cli_senha;
    const userLogin = { user, password };

    const response = await loginUser({ ...userLogin });

    localStorage.setItem("user", JSON.stringify(response.token));
  }

  //encaminhando os dados para criação do user
  async function handleSave() {
    const data = UserHandler.toDatabase(userForm);
    const user = data[0];

    await createUser(user);
  }

  const [preRegistration, setPreRegistration] = useState(false)

  useEffect(() => {
    if (!preRegistration && userForm.email !== "" && userForm.fullName !== "" && userForm.phoneNumber.length === 15 && planId) {
      postPreRegistration({
        pre_nome: userForm.fullName,
        pre_email: userForm.email,
        pre_telefone: userForm.phoneNumber,
        pre_plano: Number(planId),
      }).finally(() => { setPreRegistration(true) })
    }
  }, [userForm])

  return (
    <>
      <UserForm
        user={userForm}
        onChange={(u) => setUserForm(u)}
        setUserForm={setUserForm}
        onSave={handleSave}
        button={buttonStatus}
      />
    </>
  );
}
