import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { AccountCircle, Visibility, VisibilityOff } from "@mui/icons-material";
import PropTypes from "prop-types";
import "../styles/loginStyle.scss";
import {
  BORDER_GRAY_100,
  BUTTON_LIGHT_GREEN,
  TEXT_DARK_BLUE,
  TEXT_GRAY_600,
} from "../../constants/colors";
import { ButtonHandler } from "../../components";
import { LoginLayout } from "../../layouts";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";

export default function Login() {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    user: "",
    password: "",
    showPassword: false,
  });
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  const [renewLink, setRenewLink] = useState<any>();
  const [loadLogin, setLoadLogin] = useState(false);
  const getTokenUrl = `${process.env.REACT_APP_API_RB}/clientes/login`;

  useEffect(() => {
    getWithExpiry("renewLink");
  }, []);

  function getWithExpiry(key: any) {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }

    setRenewLink(item.value);
  }

  async function loginUser(credentials: any) {
    return await fetch(getTokenUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    })
      .then((data) => data.json())
      .finally(() => setLoadLogin(false));
  }

  function handleValidation() {
    if (!user.user)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo CPF!",
      });
    if (!user.password)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo Senha!",
      });
    return true;
  }

  async function handleLogin(e: any) {
    e.preventDefault();
    if (!handleValidation()) return;

    setLoadLogin(true);

    const response = await loginUser({ ...user });
    // console.log(response);

    setLoadLogin(false);

    if (response.error === true)
      return setStatus({ type: "error", mensagem: `${response.message}` });

    const token = response.token

    localStorage.setItem("user", JSON.stringify(token));
    Cookies.set("x-access-token", token, { expires: 365 }); // need to set the token on cookies because of embeds from portal

    // console.log(renewLink);
    if (renewLink) {
      navigate(
        `/renewLink/pagamento/mp/${renewLink[1]}/${renewLink[2]}/${renewLink[3]}`
      );
      // localStorage.removeItem("renewLink");
    } else {
      navigate("/dashboard/video");
    }
  }

  function handleChangeUser(key: string, value: any) {
    setUser({ ...user, [key]: value });
  }

  const handleClickShowPassword = () => {
    setUser({
      ...user,
      showPassword: !user.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const RenewLinkMessage = () => {
    return (
      <>
        {renewLink && (
          <Alert
            severity="success"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  localStorage.removeItem("renewLink");
                  setRenewLink(undefined);
                }}
              >
                DESATIVAR LINK
              </Button>
            }
          >
            <AlertTitle>
              Seu Link de Renovação de Plano está ativado, faça login para
              continuar!
            </AlertTitle>
            O Link irá expirar automaticamente em 10 minutos.
          </Alert>
        )}
      </>
    );
  };

  const ErrorMessage = () => {
    return (
      <>
        {status.type === "error" ? (
          <Alert
            variant="filled"
            severity="error"
            className={status.type === "error" ? "errorAssinatura" : "div-OFF"}
          >
            {status.mensagem}
          </Alert>
        ) : (
          ""
        )}
      </>
    );
  };

  const BottomContent = () => {
    return (
      <>
        <div
          className="border border-zinc-300 rounded-lg p-4 w-full"
        >
          <div
            className="flex justify-center content-center flex-nowrap"
          >
            <Typography variant="body1" color={TEXT_GRAY_600} noWrap>
              Novo na Rural Business?{" "}
              <NavLink to="/plan" className="hover:underline text-sky-700">
                Assine já
              </NavLink>
              .
            </Typography>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <RenewLinkMessage />
      <LoginLayout
        title={"Entrar"}
        errorMessage={<ErrorMessage />}
        bottomContent={<BottomContent />}
      >
        <Box
          autoComplete="off"
          component="form"
          display={"flex"}
          flexWrap={"wrap"}
          gap={"20px"}
          noValidate
          width={"100%"}
        >
          <TextField
            label="CPF"
            size="small"
            type={"tel"}
            placeholder="Apenas número"
            color="success"
            autoFocus
            required
            fullWidth
            value={user.user}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              handleChangeUser("user", e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    edge="start"
                    sx={{ cursor: "default" }}
                    className="icon-btn"
                  >
                    <AccountCircle />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{ maxLength: 14 }}
          />

          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"20px"}
            width={"100%"}
          >
            <TextField
              color="success"
              fullWidth
              placeholder="Senha"
              label="Senha"
              required
              onChange={(e) => {
                handleChangeUser("password", e.target.value);
              }}
              size="small"
              type={user.showPassword ? "text" : "password"}
              value={user.password}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="start"
                    >
                      {user.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Link
              alignSelf={"end"}
              color={TEXT_DARK_BLUE}
              href="/forgot"
              underline="hover"
              width={"fit-content"}
            >
              Esqueceu a senha?
            </Link>
          </Box>
          <ButtonHandler
            title={"Entrar"}
            color={BUTTON_LIGHT_GREEN}
            action={handleLogin}
            disabled={loadLogin}
          />
        </Box>
      </LoginLayout>
    </>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
