import axios from 'axios';
import Cookies from 'js-cookie';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_RB,
});

// Request interceptor to attach token automatically
api.interceptors.request.use(config => {
  const token = Cookies.get('x-access-token');

  if (token && config.headers) {
    config.headers['x-access-token'] = token;
  }
  return config;
});

export default api;
